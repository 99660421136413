<template>
  <div class="registry-sign-up-form">
    <div class="profile-wrapper" v-if="companyProfile">
      <div class="profile-content">
        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image">
              <img v-if="url" :src="API_URL+url" />
              <img v-else src="../../assets/images/company-logo.png" alt="" />
            </div>
          </div>

          <div class="change-profile-image justify-content-center">
            <form>
              <input
                type="file"
                id="vue-file-upload-input-company-profile-photo"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <div class="change-icon">
                <span class="text">Company Logo</span>
              </div>
            </form>
          </div>
          <div class="btns text-center">
            <button class="custom-btn2 px-4" @click="editCompanyProfile()">
              Edit Company
            </button>
          </div>
        </div>
        <div class="user-profile-data">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Company Name</span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_name"
                        v-model="details.company_name"
                        placeholder="Enter Company Name"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 p-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Company Website </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_website"
                        v-model="details.company_website"
                        placeholder="Enter Company Website"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Phone Number</span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_email"
                        v-model="details.phone_number"
                        placeholder="Enter Phone Number"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 p-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Notification Email </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_notification_email"
                        v-model="details.company_notification_email"
                        placeholder="Notification Email"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Currency</span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_email"
                        v-model="details.currency"
                        placeholder="Enter Currency"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="col-lg-8 p-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text">Company Email</span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_email"
                        v-model="details.company_email"
                        placeholder="Enter Company Email"
                        autocomplete="off"
                        readonly
                      />
                    </div>
                  </div>
                </div>
              </div>
         
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Address </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="details.address"
                    placeholder="Enter Address"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
             
            </div>
          </div>
          
        </div>
      </div>
    </div>
    <div class="profile-wrapper" v-if="updateCompanyProfile">
      <div class="profile-content">
        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image">
              <img v-if="url" :src="API_URL+url" />
              <img v-else src="../../assets/images/company-logo.png" alt="" />
            </div>
          </div>

          <div
            class="change-profile-image justify-content-center"
            @click="openinput()"
          >
            <form>
              <input
                type="file"
                id="vue-file-upload-input-company-profile-photo"
                @change="onFileChange"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <div class="change-icon">
                <span class="text">Change Company Logo</span>
              </div>
            </form>
          </div>
          <div class="profile-btns">
            <button class="custom-btn2" @click="Update()">Save Changes</button>
            <button class="custom-btn2" @click="cancelUpdate()">Cancel</button>
          </div>
        </div>
        <div class="user-profile-data">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Company Name<span class="astrick">*</span></span
                          >
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_name"
                        placeholder="Enter Company Name"
                        autocomplete="off"
                        v-model.trim="$v.details.company_name.$model"
                        :class="{
                          'is-invalid': $v.details.company_name.$error,
                          'is-valid': !$v.details.company_name.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.company_name.required"
                          >Company name is required</span
                        >
                        <span v-if="!$v.details.company_name.minLength"
                          >Company name must have at least 3
                         </span
                        >
                        <span v-if="!$v.details.company_name.maxLength"
                          >Company name must have at most 15
                          </span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 px-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Company Website <span class="astrick">*</span>
                          </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_website"
                        placeholder="Enter Company Website"
                        autocomplete="off"
                        v-model.trim="$v.details.company_website.$model"
                        :class="{
                          'is-invalid': $v.details.company_website.$error,
                          'is-valid': !$v.details.company_website.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.company_website.required"
                          >Company Website is required</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Phone Number<span class="astrick">*</span></span
                          >
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone_number"
                        placeholder="Enter Phone Number"
                        autocomplete="off"
                        v-model.trim="$v.details.phone_number.$model"
                        :class="{
                          'is-invalid': $v.details.phone_number.$error,
                          'is-valid': !$v.details.phone_number.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.phone_number.required"
                          >Phone Number is required</span
                        >
                       
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 px-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Company Email <span class="astrick">*</span>
                          </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_email"
                        placeholder="Enter Company Email"
                        autocomplete="off"
                        v-model.trim="$v.details.company_email.$model"
                        :class="{
                          'is-invalid': $v.details.company_email.$error,
                          'is-valid': !$v.details.company_email.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.company_email.required"
                          >Company Email is required</span
                        >
                        <span v-if="!$v.details.company_email.email"
                          >Invalid Email Format</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>

                <div class="col-12 px-0">
                <div class="row">
                  <div class="col-lg-4 px-0 pr-lg-2">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Currency<span class="astrick">*</span></span
                          >
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="company_name"
                        placeholder="Enter Currency"
                        autocomplete="off"
                        v-model.trim="$v.details.currency.$model"
                        :class="{
                          'is-invalid': $v.details.currency.$error,
                          'is-valid': !$v.details.currency.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.currency.required"
                          >Currency is required</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-8 px-0">
                    <div class="form-group">
                      <label for="name">
                        <div class="change-icon">
                          <span class="text"
                            >Notification Email <span class="astrick">*</span>
                          </span>
                        </div>
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id=" company_notification_email"
                        placeholder="Enter Notification Email"
                        autocomplete="off"
                        v-model.trim="$v.details.company_notification_email.$model"
                        :class="{
                          'is-invalid': $v.details.company_notification_email.$error,
                          'is-valid': !$v.details.company_notification_email.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.company_notification_email.required"
                          >Notification Email is required</span
                        >
                        <span v-if="!$v.details.company_notification_email.email"
                          >Invalid Email Format</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
             
              <div class="col-lg-12 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Company Address 1<span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    placeholder="Enter Address"
                    autocomplete="off"
                    v-model.trim="$v.details.address.$model"
                    :class="{
                      'is-invalid': $v.details.address.$error,
                      'is-valid': !$v.details.address.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.address.required"
                      >Address is required</span
                    >
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  url,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "CompanyProfile",
  data() {
    return {
      companyProfile: true,
      updateCompanyProfile: false,
      time: this.$time,
      API_URL: this.$companyLogo,
      url: null,
      isLoadingArray: [],
      companyLogo: null,
      oldPicture: true,
      details: {
        company_name: "",
        company_website: "",
        phone_number: "",
        currency: "",
        company_email: "",
        company_notification_email: "",
        address:""
      },
   
    };
  },
  validations: {
    details: {
      company_name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      company_website: {
        required,
        url,
      },
      address: {
        required,
      },
      
      phone_number: {
        required,
      },
      company_email: {
        required,
         email,
      },
      company_notification_email: {
        required,
        email,
      },
      currency: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    editCompanyProfile: function() {
      this.updateCompanyProfile = true;
      this.companyProfile = false;
    },
    cancelUpdate: function() {
      this.companyProfile = true;
      this.updateCompanyProfile = false;
      this.dataGet();
    },
    setSelected(value) {
      this.details.state = value.name;
    },
    setSelectedindus(value) {
      this.details.Industry = value.name;
    },
    openinput: function() {
      document
        .getElementById("vue-file-upload-input-company-profile-photo")
        .click();
    },
    onFileChange: function(e) {
      this.oldPicture = false;
      this.companyLogo = e.target.files[0];
      this.url = URL.createObjectURL(this.companyLogo);
    },
    Update: function(index) {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      var formData = new FormData();
   
      formData.append("company_name", this.details.company_name);
      formData.append("company_website", this.details.company_website);
      formData.append("address", this.details.address);
      formData.append("phone_number", this.details.phone_number);
      formData.append("currency", this.details.currency);
      formData.append("company_notification_email", this.details.company_notification_email);
      formData.append("company_email", this.details.company_email);
      formData.append("company_logo", this.companyLogo);
     
      axios
        .post(process.env.VUE_APP_API_URL + "companyUpdate", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal(
              "Updated!",
              "Your Comapny Details has been Updated.",
              "success"
            );
            this.$set(this.isLoadingArray, index, false);
            this.getUserData();
            this.companyProfile = true;
            this.updateCompanyProfile = false;
            this.verify_email_check = 0;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.verify_email_check = 0;
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    async dataGet() 
    {
      await this.getUserData();
      if (this.user) {
        if (this.user.data.companyData != null) {
          this.details.company_name = this.user.data.companyData.company_name;
          this.details.company_website = this.user.data.companyData.company_website;
          this.url = this.user.data.companyData.company_logo;
          this.details.company_notification_email = this.user.data.companyData.company_notification_email;
          this.details.address = this.user.data.companyData.address;
          this.details.currency = this.user.data.companyData.currency;
          this.details.company_notification_email = this.user.data.companyData.company_notification_email;
          this.details.company_email = this.user.data.companyData.company_email;
          this.details.phone_number = this.user.data.companyData.phone_number;
        }
      }
    },
  },
  mounted() {
    this.dataGet();
  },
};
</script>

<style>
.errorEmail {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
