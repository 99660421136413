<template>
  <div>
    <div class="top-wrapper">
      <h6>Configurations</h6>
    </div>
    <ul class="nav nav-tabs nav-pills" id="myTab" role="tablist">
      <li class="nav-item" @click="callCrud(1)">
        <a class="nav-link active" data-toggle="tab">
          Add First Process
        </a>
      </li>
      <li class="nav-item" @click="callCrud(2)">
        <a class="nav-link" data-toggle="tab">Add Second Process</a>
      </li>
      <li class="nav-item" @click="callCrud(8)">
        <a class="nav-link" data-toggle="tab">Update Second Process</a>
      </li>
      <li class="nav-item" @click="callCrud(3)">
        <a class="nav-link" data-toggle="tab">
          Add Metal
        </a>
      </li>
      <li class="nav-item" @click="callCrud(4)">
        <a class="nav-link" data-toggle="tab">Add Shape</a>
      </li>
      <li class="nav-item" @click="callCrud(5)">
        <a class="nav-link" data-toggle="tab">
          Add Gemstone
        </a>
      </li>
      <!-- <li class="nav-item" @click="callCrud(6)">
        <a class="nav-link" data-toggle="tab">Add Style</a>
      </li> -->
      <li class="nav-item" @click="callCrud(7)">
        <a class="nav-link" data-toggle="tab">Add Setting Type</a>
      </li>
    </ul>
    <!-- <div class="top-wrapper-process">
      <button class="custom-btn4" @click="callCrud(1)">
        Add First Process
      </button>
      <button class="custom-btn4" @click="callCrud(2)">
        Add Second Process
      </button>
      <button class="custom-btn4" @click="callCrud(3)">Add Metal</button>
      <button class="custom-btn4" @click="callCrud(4)">Add Shape</button>
      <button class="custom-btn4" @click="callCrud(5)">Add Gemstone</button>
      <button class="custom-btn4" @click="callCrud(6)">Add Style</button>
      <button class="custom-btn4" @click="callCrud(7)">Add Setting type</button>
    </div> -->
    <div v-if="process_1" class="">
      <processFirst />
    </div>
    <div v-if="process_2" class="">
      <processSecond />
    </div>
    <div v-if="metalCrud" class="">
      <metal  />
    </div>
    <div v-if="shapeCrud" class="">
      <shape  />
    </div>
    <div v-if="gemstoneCrud" class="">
      <Gemstone />
    </div>
    <div v-if="styleCrud" class="">
      <styleRing />
    </div>
    <div v-if="settingCrud" class="">
      <SettingType/>
    </div>
    <div v-if="UpdateProcessCrud" class="">
      <UpdateProcessCrud  />
    </div>
  </div>
</template>

<script>
import processFirst from "./processFirst.vue";
import processSecond from "./processSecond.vue";
import metal from "./metal.vue";
import styleRing from "./style.vue";
import shape from "./shape.vue";
import Gemstone from "./gemstone.vue";
import SettingType from "./setting.vue";
import UpdateProcessCrud from "./updateProcessSecond.vue"
export default {
  name: "ProcessButtons",
  components: {
    processFirst,
    processSecond,
    metal,
    styleRing,
    shape,
    Gemstone,
    SettingType,
    UpdateProcessCrud
  },
  data() {
    return {
      process_1: true,
      process_2: false,
      metalCrud: false,
      shapeCrud: false,
      gemstoneCrud: false,
      styleCrud: false,
      settingCrud: false,
      UpdateProcessCrud: false,
      subProcessOptions: [],
    };
  },
  methods: {
    callCrud(upStatus) {
      if (upStatus == 1) {
        this.process_1 = true;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 2) {
        this.process_1 = false;
        this.process_2 = true;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 3) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = true;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 4) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = true;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 5) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = true;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 6) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = true;
        this.settingCrud = false;
        this.UpdateProcessCrud = false;
      } else if (upStatus == 7) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = true;
        this.UpdateProcessCrud = false;
      }
      else if (upStatus == 8) {
        this.process_1 = false;
        this.process_2 = false;
        this.metalCrud = false;
        this.shapeCrud = false;
        this.gemstoneCrud = false;
        this.styleCrud = false;
        this.settingCrud = false;
        this.UpdateProcessCrud = true;
      }
    },
  },
  mounted() {
  },
};
</script>

<style>
.bg-black {
  background-color: black;
}
</style>
