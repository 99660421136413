<template>
  <div class="profile-wrapper">
    <form>
      <div class="registry-sign-up-form-content">
        <div class="company-info-wrapper">
          <div class="info-top">
            <h6>Add Gemstone</h6>
          </div>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-2 px-0 pr-md-2">
                <div class="user-profile-image">
                  <div class="profile-image">
                    <div id="preview" class="profile-image">
                      <img v-if="url" :src="url" />
                      <img
                        v-else
                        src="../../assets/images/product-image.png"
                        alt=""
                      />
                    </div>
                  </div>

                  <div class="change-profile-image" @click="openinput()">
                    <form>
                      <input
                        type="file"
                        id="vue-file-upload-input-profile-photo"
                        class="d-none"
                        @change="onFileChange"
                        accept="image/png, image/gif, image/jpeg"
                      />

                      <div class="change-icon">
                        <span class="text">Add Picture</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-10 p-0">

                <div class="row">
                  <div class="col-12 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="customer_name"
                        >Name <span class="astrick">*</span></label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="process_name"
                        placeholder=" Name"
                        autocomplete="off"
                        v-model.trim="$v.details.name.$model"
                        :class="{
                          'is-invalid': $v.details.name.$error,
                          'is-valid': !$v.details.name.$invalid,
                        }"
                      />
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.name.required"
                          >Name is required</span
                        >
                        <span v-if="!$v.details.name.minLength"
                          >Name must have at least
                          {{ $v.details.name.$params.minLength.min }}</span
                        >
                        <span v-if="!$v.details.name.maxLength"
                          >Name must have at most
                          {{ $v.details.name.$params.maxLength.max }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12 px-0">
                <div
                  class="form-group d-flex align-items-center justify-content-start"
                >
                  <input
                    type="checkbox"
                    class="mr-2"
                    v-model="details.status"
                    id="customerActive"
                  />
                  <label class="form-check-label" for="customerActive"
                    >This Gemstone is active</label
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="custom-btn2"
        @click="saveData(0)"
        :disabled="isLoadingArray[0]"
      >
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Save</span>
      </button>
    </form>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Gemstone",
  props: {
    subProcessOptions: Array,
  },
  data: function() {
    return {
      details: {
        name: "",
        status: true,
      },
      url: null,
      profilePhoto: null,
      oldPicture: true,
      isLoadingArray: [],
      processPlaceholder: "Select Second Process",
    };
  },
  validations: {
    details: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(25),
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    saveData: function(index) {
      var formData = new FormData();
      formData.append("name", this.details.name);
      formData.append("picture", this.profilePhoto);
      formData.append("status", this.details.status == true ? 1 : 2);

      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "process/createGemstone", formData)
        .then((response) => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$toast.success("Gemstone is added successfully", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push({ name: "Configuration" });
          } else {
            alert(response.data.message);
          }
        })
        .catch((error) => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
  },
  mounted() {},
};
</script>
