var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-wrapper"},[_c('form',[_c('div',{staticClass:"registry-sign-up-form-content"},[_c('div',{staticClass:"company-info-wrapper"},[_vm._m(0),_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-4 col-lg-2 px-0 pr-md-2"},[_c('div',{staticClass:"user-profile-image"},[_c('div',{staticClass:"profile-image"},[_c('div',{staticClass:"profile-image",attrs:{"id":"preview"}},[(_vm.url)?_c('img',{attrs:{"src":_vm.url}}):_c('img',{attrs:{"src":require("../../assets/images/product-image.png"),"alt":""}})])]),_c('div',{staticClass:"change-profile-image",on:{"click":function($event){return _vm.openinput()}}},[_c('form',[_c('input',{staticClass:"d-none",attrs:{"type":"file","id":"vue-file-upload-input-profile-photo","accept":"image/png, image/gif, image/jpeg"},on:{"change":_vm.onFileChange}}),_vm._m(1)])])])]),_c('div',{staticClass:"col-12 col-md-6 col-lg-10 p-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('v-select',{staticClass:"form-control pl-2",class:{
                        'is-invalid': _vm.$v.details.process1_id.$error,
                        'is-valid': !_vm.$v.details.process1_id.$invalid,
                      },attrs:{"options":_vm.processOptions,"label":"name","placeholder":_vm.processPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.process1_id.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.process1_id, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.process1_id.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.process1_id.required)?_c('span',[_vm._v("First Process is required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.details.name.$model),expression:"$v.details.name.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                        'is-invalid': _vm.$v.details.name.$error,
                        'is-valid': !_vm.$v.details.name.$invalid,
                      },attrs:{"type":"text","id":"process_name","placeholder":"Name","autocomplete":"off"},domProps:{"value":(_vm.$v.details.name.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.details.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.name.required)?_c('span',[_vm._v("Process name is required")]):_vm._e(),(!_vm.$v.details.name.minLength)?_c('span',[_vm._v(" Process name must have at least "+_vm._s(_vm.$v.details.name.$params.minLength.min)+" ")]):_vm._e(),(!_vm.$v.details.name.maxLength)?_c('span',[_vm._v(" Process name must have at most "+_vm._s(_vm.$v.details.name.$params.maxLength.max)+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(4),_c('v-select',{staticClass:"form-control pl-2",class:{
                        'is-invalid': _vm.$v.details.metals.$error,
                        'is-valid': !_vm.$v.details.metals.$invalid,
                      },attrs:{"options":_vm.metalsOptions,"label":"name","placeholder":_vm.metalsPlaceholder,"multiple":"","autocomplete":"off"},model:{value:(_vm.$v.details.metals.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.metals, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.metals.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.metals.required)?_c('span',[_vm._v("Metals are required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(5),_c('v-select',{staticClass:"form-control pl-2",class:{
                        'is-invalid': _vm.$v.details.shapes.$error,
                        'is-valid': !_vm.$v.details.shapes.$invalid,
                      },attrs:{"options":_vm.shapesOptions,"label":"name","multiple":"","placeholder":_vm.shapesPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.shapes.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.shapes, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.shapes.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.shapes.required)?_c('span',[_vm._v("Shapes are required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(6),_c('v-select',{staticClass:"form-control pl-2",class:{
                        'is-invalid': _vm.$v.details.gemstones.$error,
                        'is-valid': !_vm.$v.details.gemstones.$invalid,
                      },attrs:{"options":_vm.gemstonesOptions,"label":"name","multiple":"","placeholder":_vm.gemstonesPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.gemstones.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.gemstones, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.gemstones.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.gemstones.required)?_c('span',[_vm._v("Gemstones are required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group"},[_vm._m(7),_c('v-select',{staticClass:"form-control pl-2",class:{
                        'is-invalid': _vm.$v.details.setting_types.$error,
                        'is-valid': !_vm.$v.details.setting_types.$invalid,
                      },attrs:{"options":_vm.setting_typesOptions,"label":"name","multiple":"","placeholder":_vm.setting_typesPlaceholder,"autocomplete":"off"},model:{value:(_vm.$v.details.setting_types.$model),callback:function ($$v) {_vm.$set(_vm.$v.details.setting_types, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.details.setting_types.$model"}}),_c('div',{staticClass:"invalid-tooltip"},[(!_vm.$v.details.setting_types.required)?_c('span',[_vm._v("Setting Types are required")]):_vm._e()])],1)]),_c('div',{staticClass:"col-6 col-md-6 px-0 pr-md-2"},[_c('div',{staticClass:"form-group d-flex align-items-center justify-content-start py-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details.status),expression:"details.status"}],staticClass:"mr-2",attrs:{"type":"checkbox","id":"customerActive"},domProps:{"checked":Array.isArray(_vm.details.status)?_vm._i(_vm.details.status,null)>-1:(_vm.details.status)},on:{"change":function($event){var $$a=_vm.details.status,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.details, "status", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.details, "status", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.details, "status", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"customerActive"}},[_vm._v("This Process is active")])])])])])])])])]),_c('button',{staticClass:"custom-btn2",attrs:{"type":"button","disabled":_vm.isLoadingArray[0]},on:{"click":function($event){return _vm.saveData(0)}}},[(_vm.isLoadingArray[0])?_c('div',{staticClass:"lds-dual-ring"}):_c('span',[_vm._v("Save")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info-top"},[_c('h6',[_vm._v("Add Second Process step")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"change-icon"},[_c('span',{staticClass:"text"},[_vm._v("Add Picture")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Select First Process "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"customer_name"}},[_vm._v(" Name "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Select Metals "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Select Shapes "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Select Gemstones "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"contact_person"}},[_vm._v(" Select Setting type "),_c('span',{staticClass:"astrick"},[_vm._v("*")])])
}]

export { render, staticRenderFns }