<template>
  <div class="profile-wrapper">
    <form>
      <div class="registry-sign-up-form-content">
        <div class="company-info-wrapper">
          <div class="info-top">
            <h6>Update Second Process</h6>
          </div>

          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12 col-md-4 col-lg-2 px-0 pr-md-2">
                <div class="user-profile-image">
                  <div class="profile-image">
                    <div id="preview" class="profile-image">
                      <img v-if="url" :src="url" />
                      <img v-else src="../../assets/images/product-image.png" alt />
                    </div>
                  </div>

                  <div class="change-profile-image" @click="openinput()">
                    <form>
                      <input
                        type="file"
                        id="vue-file-upload-input-profile-photo"
                        class="d-none"
                        @change="onFileChange"
                        accept="image/png, image/gif, image/jpeg"
                      />

                      <div class="change-icon">
                        <span class="text">Add Picture</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6 col-lg-10 p-0">
                <div class="row">
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="contact_person">
                        Select Second Process
                        <span class="astrick">*</span>
                      </label>
                      <v-select
                        v-model.trim="$v.details.process2_id.$model"
                        :options="processOptions"
                        label="name"
                        :placeholder="processPlaceholder"
                        class="form-control pl-2"
                        :class="{
                          'is-invalid': $v.details.process2_id.$error,
                          'is-valid': !$v.details.process2_id.$invalid,
                        }"
                        autocomplete="off"
                      ></v-select>
                      <div class="invalid-tooltip">
                        <span v-if="!$v.details.process2_id.required">Second Process is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="contact_person">
                        Select Metals
                        <span class="astrick">*</span>
                      </label>
                      <v-select
                        v-model="details.metals"
                        :options="metalsOptions"
                        label="name"
                        :placeholder="metalsPlaceholder"
                        multiple
                        class="form-control pl-2"
                        autocomplete="off"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="contact_person">
                        Select Shapes
                        <span class="astrick">*</span>
                      </label>
                      <v-select
                        v-model="details.shapes"
                        :options="shapesOptions"
                        label="name"
                        multiple
                        :placeholder="shapesPlaceholder"
                        class="form-control pl-2"
                        autocomplete="off"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="contact_person">
                        Select Gemstones
                        <span class="astrick">*</span>
                      </label>
                      <v-select
                        v-model="details.gemstones"
                        :options="gemstonesOptions"
                        label="name"
                        multiple
                        :placeholder="gemstonesPlaceholder"
                        class="form-control pl-2"
                        autocomplete="off"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group">
                      <label for="contact_person">
                        Select Setting type
                        <span class="astrick">*</span>
                      </label>
                      <v-select
                        v-model="details.setting_types"
                        :options="setting_typesOptions"
                        label="name"
                        multiple
                        :placeholder="setting_typesPlaceholder"
                        class="form-control pl-2"
                        autocomplete="off"
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-6 col-md-6 px-0 pr-md-2">
                    <div class="form-group d-flex align-items-center justify-content-start py-4">
                      <input
                        type="checkbox"
                        class="mr-2"
                        v-model="details.status"
                        id="customerActive"
                      />
                      <label class="form-check-label" for="customerActive">This Process is active</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="custom-btn2" @click="saveData(0)" :disabled="isLoadingArray[0]">
        <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
        <span v-else>Save</span>
      </button>
    </form>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "processSecond",
  data: function() {
    return {
      details: {
        status: true,
        process2_id: "",
        metals: "",
        shapes: "",
        gemstones: "",
        setting_types: ""
      },
      url: null,
      profilePhoto: null,
      oldPicture: true,
      isLoadingArray: [],
      processOptions: [],
      processPlaceholder: "Select First Process",
      metalsOptions: [],
      metalsPlaceholder: "Select metals",
      shapesOptions: [],
      shapesPlaceholder: "Select shapes",
      gemstonesOptions: [],
      gemstonesPlaceholder: "Select gemstones",
      setting_typesOptions: [],
      setting_typesPlaceholder: "Select Setting Types"
    };
  },
  validations: {
    details: {
      process2_id: {
        required
      },
    }
  },
  computed: {
    ...mapGetters("auth", ["user"])
  },
  methods: {
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    saveData: function(index) {
      var formData = new FormData();
      formData.append("process2_id", this.details.process2_id.id);
      formData.append("picture", this.profilePhoto);
      formData.append("status", this.details.status == true ? 1 : 2);
      if(this.details.metals != ""){
        formData.append("metals", JSON.stringify(this.details.metals));
      }
      if(this.details.shapes != ""){
        formData.append("shapes", JSON.stringify(this.details.shapes));
      }
      if(this.details.gemstones != ""){
        formData.append("gemstones", JSON.stringify(this.details.gemstones));
      }
      if(this.details.setting_types != ""){
        formData.append(
        "setting_types",
        JSON.stringify(this.details.setting_types)
      );
      }
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      this.$set(this.isLoadingArray, index, true);
      axios
        .post(process.env.VUE_APP_API_URL + "process/updateProcess2", formData)
        .then(response => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$toast.success("Process two is Updated successfully", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: false,
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              draggable: false,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            this.clearForm();
            this.$router.push({ name: "Configuration" });
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    },
    getFirstProcess() {
      axios
        .post(`${process.env.VUE_APP_API_URL}process/process2Listing`)
        .then(response => {
          if (response.data.statusCode == 200) {
            this.processOptions = response.data.data.process;
          }
        });
    },
    clearForm() {
      this.details.name = "";
      this.details.status = true;
      this.details.process2_id = "";
      this.details.metals = "";
      this.details.shapes = "";
      this.details.gemstones = "";
      this.details.setting_types = "";
    },
    getConfigs() {
      axios
        .post(`${process.env.VUE_APP_API_URL}process/getConfigs`)
        .then(response => {
          if (response.data.statusCode == 200) {
            this.metalsOptions = response.data.data.metals;
            this.shapesOptions = response.data.data.shape;
            this.gemstonesOptions = response.data.data.gemstone;
            this.setting_typesOptions = response.data.data.settingType;
          }
        });
    }
  },
  mounted() {
    this.getFirstProcess();
    this.getConfigs();
  }
};
</script>
