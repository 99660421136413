<template>
  <div class="registry-sign-up-form">
    <div class="profile-wrapper" v-if="userProfile">
      <div class="profile-content">
        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image">
              <img v-if="url" :src="url" />
              <img v-else src="../../assets/images/user-image.png" alt="" />
            </div>
          </div>

          <div class="change-profile-image justify-content-center">
            <form>
              <input
                type="file"
                id="vue-file-upload-input-profile-photo"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <div class="change-icon">
                <span class="text">Profile Picture</span>
              </div>
            </form>
          </div>
          <div class="btns text-center">
            <button class="custom-btn2 px-4" @click="EditProfile()">
              Edit Profile
            </button>
          </div>
        </div>
        <div class="user-profile-data">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Name</span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name1"
                    v-model="details.name"
                    placeholder="Enter First Name"
                    readonly
                  />
                </div>
              </div>
          
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Email </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name3"
                    v-model="details.email"
                    placeholder="Enter Email"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
             
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Change Password</span>
                    </div>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="New Password"
                    autocomplete="off"
                    v-model.trim="$v.password"
                    readonly
                  />
                </div>
              </div>
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Confirm Password</span>
                    </div>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    autocomplete="off"
                    v-model.trim="$v.confirmPassword"
                    readonly
                  />
                </div>
              </div>

               <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Phone Number </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    v-model="details.mob"
                    placeholder="Enter Phone Number"
                    autocomplete="off"
                    readonly
                  />
                </div>
              </div>
           
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="profile-wrapper" v-if="updateProfile">
      <div class="profile-content">
        <div class="user-profile-image">
          <div class="profile-image">
            <div id="preview" class="profile-image">
              <img v-if="url" :src="url" />
              <img v-else src="../../assets/images/user-image.png" alt="" />
            </div>
          </div>

          <div
            class="change-profile-image justify-content-center"
            @click="openinput()"
          >
            <form>
              <input
                type="file"
                id="vue-file-upload-input-profile-photo"
                @change="onFileChange"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
              />

              <div class="change-icon">
                <span class="text">Change Profile Picture</span>
              </div>
            </form>
          </div>
          <div class="profile-btns">
            <button class="custom-btn2" @click="Update()">
              Save Changes
            </button>
            <button class="custom-btn2" @click="cancelUpdate()">
              Cancel
            </button>
          </div>
        </div>
        <div class="user-profile-data">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >First Name <span class="astrick">*</span></span
                      >
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name1"
                    placeholder="Enter Name"
                    autocomplete="off"
                    v-model.trim="$v.details.name.$model"
                    :class="{
                      'is-invalid': $v.details.name.$error,
                      'is-valid': !$v.details.name.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.name.required"
                      >First name is required</span
                    >
                    <span v-if="!$v.details.name.minLength"
                      >First name must have at least
                      {{ $v.details.name.$params.minLength.min }}</span
                    >
                    <span v-if="!$v.details.name.maxLength"
                      >First name must have at most
                      {{ $v.details.name.$params.maxLength.max }}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Email <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="name3"
                    placeholder="Enter Email"
                    autocomplete="off"
                    v-model.trim="$v.details.email.$model"
                    :class="{
                      'is-invalid': $v.details.email.$error,
                      'is-valid': !$v.details.email.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.email.required"
                      >Email is required</span
                    >
                    <span v-if="!$v.details.email.email"
                      >Email must be valid
                    </span>
                  </div>
                </div>
              </div>
              

              <div class="col-lg-6 px-0 pr-lg-2">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Change Password</span>
                    </div>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    placeholder="New Password"
                    autocomplete="off"
                    v-model.trim="$v.password.$model"
                    :class="{
                      'is-invalid': $v.password.$error,
                      'is-valid': !$v.password.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.password.required"
                      >Password is required</span
                    >
                    <span v-if="!$v.password.minLength"
                      >Password must have at least
                      {{ $v.password.$params.minLength.min }}
                      letters</span
                    >
                    <span v-if="!$v.password.maxLength"
                      >Password must have at most
                      {{ $v.password.$params.maxLength.max }}
                      letters</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text">Confirm Password</span>
                    </div>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id="confirmPassword"
                    placeholder="Confirm Password"
                    autocomplete="off"
                    v-model.trim="$v.confirmPassword.$model"
                    :class="{
                      'is-invalid': $v.confirmPassword.$error,
                      'is-valid': !$v.confirmPassword.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.confirmPassword.required"
                      >Confirm Password is required</span
                    >
                    <span v-else>Passwords must be identical.</span>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 px-0">
                <div class="form-group">
                  <label for="name">
                    <div class="change-icon">
                      <span class="text"
                        >Phone Number <span class="astrick">*</span>
                      </span>
                    </div>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="phone"
                    placeholder="Enter Phone Number"
                    autocomplete="off"
                    v-model.trim="$v.details.mob.$model"
                    :class="{
                      'is-invalid': $v.details.mob.$error,
                      'is-valid': !$v.details.mob.$invalid,
                    }"
                  />
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.mob.required"
                      >Phone Number is required</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  email,
  sameAs,
} from "vuelidate/lib/validators";
export default {
  name: "UserProfile",
  data() {
    return {
      userProfile: true,
      updateProfile: false,
      time: this.$time,
      API_URL: this.$userAvatar,
      url: null,
      isLoadingArray: [],
      profilePhoto: "",
      oldPicture: true,
      password: "",
      confirmPassword: "",
      details: {
        name: "",
        email: "",
        mob: "",
      },
    };
  },
  validations: {
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(15),
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
    details: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(15),
      },
      email: {
        required,
        email,
      },
      mob: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  methods: {
    ...mapActions("auth", ["getUserData"]),
    EditProfile: function() {
      this.updateProfile = true;
      this.userProfile = false;
      this.profileData();
    },
    cancelUpdate: function() {
      this.userProfile = true;
      this.updateProfile = false;
      this.profileData();
    },
    onFileChange(e) {
      this.oldPicture = false;
      this.profilePhoto = e.target.files[0];
      this.url = URL.createObjectURL(this.profilePhoto);
    },
    openinput: function() {
      document.getElementById("vue-file-upload-input-profile-photo").click();
    },
    Update: function(index) {
      var formData = new FormData();
      if (this.password != "") {
        this.$v.password.$touch();
        this.$v.confirmPassword.$touch();
        if (
          this.$v.password.$pendding ||
          this.$v.password.$error ||
          this.$v.confirmPassword.$pendding ||
          this.$v.confirmPassword.$error
        )
          return;
        formData.append("password", this.password);
      } else {
        this.$v.details.$touch();
        if (this.$v.details.$pendding || this.$v.details.$error) return;
      }
      this.$set(this.isLoadingArray, index, true);
      formData.append("name", this.details.name);
      formData.append("email", this.details.email);
      formData.append("mob", this.details.alternate_email)
      formData.append("picture", this.profilePhoto);
     
      axios
        .post(process.env.VUE_APP_API_URL + "userUpdate", formData)
        .then((response) => {
          if (response.data.statusCode === 200) {
            this.$swal("Updated!", "Your Profile has been Updated.", "success");
            this.$set(this.isLoadingArray, index, false);
            this.getUserData();
            this.userProfile = true;
            this.updateProfile = false;
          }
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.data.message);
            this.$set(this.isLoadingArray, index, false);
          }
        });
    },
    async profileData() 
    {
      await this.getUserData();
      if (this.user) {
        this.details.name = this.user.data.userData.name;
        this.url = (this.user.data.userData.picture)?this.API_URL+this.user.data.userData.picture:'';
        this.details.mob = this.user.data.userData.mob;
        this.details.email =this.user.data.userData.email;
      }
    },
  },
  mounted() {
    this.profileData();
  },
};
</script>

<style></style>
